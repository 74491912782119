import * as React from 'react'
import { PageProps } from 'gatsby'
import { Layout } from '../../components/common/Layout'
import { SEO } from '../../components/common/SEO'
import { ContactConfirm, ContactHeader } from '../../components/domains/contact'

const ContactPage = (props: PageProps) => {
	return (
		<Layout
			location={[
				{ name: 'お問い合わせ', path: '/contact' },
				{ name: 'お問い合わせ 入力内容確認', path: '/contact/confirm' },
			]}
		>
			<SEO title="お問い合わせ" description="" />
			<ContactHeader currentStep={2} />
			<ContactConfirm location={props.location} />
		</Layout>
	)
}

export default ContactPage
